import React from 'react';
import { Layout } from '../components/Layout';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { device } from '../theme';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ShareWidget } from '../components/ShareWidget';
import { SEO } from '../components/SEO';
import { CodeHighlight } from '../components/CodeHighlight';
import _ from 'lodash';
import { Link } from 'gatsby';
dayjs.extend(utc);

interface SLWProps {
  hasFeaturedImage: boolean;
}
const StyledLayoutWrapper = styled.div<SLWProps>`
${({ hasFeaturedImage }) =>
  hasFeaturedImage
    ? css`
        display: grid;
        grid-template-columns: minmax(300px, 520px) minmax(200px, auto);
        grid-template-rows: auto;
        gap: 40px;
      `
    : css`
        max-width: 600px;
        margin: 0 auto;
      `}
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const StyledFeaturedImage = styled(Img)`
  max-height: 600px;
  border-radius: 5px;
`;

const StyledArticle = styled.article``;

const StyledArticleHeader = styled.header`
  h1 {
    margin: 5px 0;
    font-size: 3rem;
    font-weight: 300;
    line-height: 1em;
  }
`;

interface Props {
  pageContext: any;
}

const ArticlePage = ({ pageContext }: Props) => {
  const { basePath } = pageContext;

  const { node: article } = pageContext.article;
  return (
    <Layout>
      <SEO
        title={article.title}
        description={article.excerpt}
        ogImage={article.featuredImage.childImageSharp.fluid.src}
      />
      <StyledLayoutWrapper hasFeaturedImage={article.featuredImage}>
        {article.featuredImage && (
          <StyledFeaturedImage
            fluid={article.featuredImage.childImageSharp.fluid}
          />
        )}
        <StyledArticle>
          <StyledArticleHeader>
            <span>
              <Link
                to={`${basePath}/category/${_.kebabCase(article.category)}`}
              >
                {article.category}
              </Link>
            </span>
            <h1>{article.title}</h1>
            <time>{dayjs.utc(article.date).format('MM/DD/YYYY')}</time>
          </StyledArticleHeader>
          <ShareWidget />

          <MDXProvider components={{ code: CodeHighlight }}>
            <MDXRenderer>{article.body}</MDXRenderer>
          </MDXProvider>
        </StyledArticle>
      </StyledLayoutWrapper>
    </Layout>
  );
};

export default ArticlePage;
